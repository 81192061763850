import React from 'react'
import {
  graphql,
} from 'gatsby'
import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import {
  Page,
  Main,
  SubTitle,
  Content,
  IntroParagraph,
} from '../../components/StyledComponents'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import PageHero from '../../components/Blocks/PageHero'
import IconCustom from '../../components/Base/Icon/Custom'
import BottomOverlay from '../../components/Blocks/BottomOverlay'

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "page-hero/hero-request-small.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    desktopImage: file(
      relativePath: { eq: "page-hero/hero-request.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ({
  data,
}) => {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 800px)',
    },
  ]
  return (
    <Layout>
      <SEO
        title='Thank you!'
        description='We have received your ChartMedic account request. We will review
      your submission and get back to you soon!'
      />
      <Page id='site-page'>
        <Header />
        <PageHero
          background={sources}
          title='Thank You!'
        />
        <Main>
          <Content short center>
            <IconCustom theme='handshake' />
            <SubTitle>We’ll Be in Touch.</SubTitle>
            <IntroParagraph>
              We have received your ChartMedic account request. We will review
              your submission and get back to you soon!
            </IntroParagraph>
          </Content>
        </Main>
      </Page>
      <Footer />
      <BottomOverlay />
    </Layout>
  )
}
